import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
  url: 'https://solem.auris.cloud/auth',
  realm: 'solem',
  clientId: '9471882f-e11d-203e-532b-1659fee99039'
})

export const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false
    })
    .then(authenticated => {
      if (!authenticated) {
        keycloak.login()
      } else {
        onAuthenticatedCallback()
        // console.log('Keycloak', keycloak)
      }
    })
    .catch(err => {
      console.error('Error initializing Keycloak:', err)
    })
}

export const KeycloakInstance = keycloak
