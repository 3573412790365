import Vue from 'vue'
import Router from 'vue-router'
import { KeycloakInstance } from '@/keycloak'

Vue.use(Router)

const requireAuth = (to, from, next) => {
  if (KeycloakInstance.authenticated) {
    next()
  } else {
    KeycloakInstance.login({ redirectUri: window.location.href })
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/home',
      name: 'landing',
      component: () => import('@/components/Landing'),
      beforeEnter: requireAuth
    },
    {
      path: '/forms/:typeRequest(hallazgo|incidente)',
      name: 'forms',
      component: () => import('@/components/Denuncia'),
      beforeEnter: requireAuth
    },
    {
      path: '/resultado/:typeRequest/:success(success|error)/:message',
      name: 'resultado',
      component: () => import('@/components/Resultado'),
      beforeEnter: requireAuth
    },
    {
      path: '/ticket/:contactId/:serialId',
      name: 'detalle',
      component: () => import('@/components/Detalle'),
      beforeEnter: requireAuth
    },
    {
      path: '/*',
      redirect: '/home'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
