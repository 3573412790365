<template>
  <!-- modal -->
  <div v-if="showModal" class="modal" :class="{ 'show': showModal }" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-title d-flex align-items-center">
        <div class="col">
          <h3 class="col-12">Crear ticket</h3>
        </div>
        <div class="col text-end">
          <a href="javascript:void(0)" @click="closeModal">
            <img class="text-end" src="@/assets/img/icons/x.svg" alt="">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col mt-2">
          <p class="text-regular">Para de crear un ticket selecciona que tipo de reporte quieres generar.
          </p>
        </div>
      </div>
      <div class="col-12 d-block d-sm-block d-md-flex d-md-flex d-lg-flex ">
        <div class="col box text-center">
          <img src="@/assets/img/hallazgo.png" alt="">
          <h5>Reporte de hallazgos</h5>
          <p class="text-regular ">
            Si has descubierto un evento de seguridad en alguno de nuestros canales, productos o
            servicios, te animamos a contactarnos y contarnos tu hallazgo.
          </p>
          <div class="col d-flex justify-content-center buttons">
            <router-link class="btn btn-secondary-blue text-semibold"
              :to="{ name: 'forms', params: { typeRequest: 'hallazgo' } }" @click.native="closeModal">Reportar hallazgo</router-link>
          </div>

        </div>
        <div class="col box text-center">
          <img src="@/assets/img/incidente.png" alt="">
          <h5>Reporte Incidente Interno</h5>
          <p class="text-regular">
            Si cuentas con una degradación o indisponibilidad de alguno de los activos con los cuáles
            trabajas diariamente, puedes reportarlo aquí para que la unidad encargada de administrar ese
            activo pueda resolverlo.
          </p>
          <div class="col d-flex justify-content-center buttons">
            <router-link class="btn btn-secondary-purple text-semibold"
              :to="{ name: 'forms', params: { typeRequest: 'incidente' } }" @click.native="closeModal">Reportar incidente</router-link>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col d-flex justify-content-center mt-3">
          <button type="button" class="btn btn-linkcancel text-semibold" @click="closeModal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    openModal () {
      this.showModal = true
      document.body.classList.add('no-scroll')
    },
    closeModal () {
      this.showModal = false
      document.body.classList.remove('no-scroll')
    }
  }
}
</script>
