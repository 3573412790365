<template>
  <div id="app">
    <HeaderComponent />
    <router-view  @open-modal="openModal" />
    <ModalComponent ref="modalComponent" />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/Header.vue'
import FooterComponent from './components/Footer.vue'
import ModalComponent from './components/ModalCreate.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    ModalComponent
  },
  methods: {
    openModal () {
      this.$refs.modalComponent.openModal()
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.modalComponent.closeModal()
    next()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
</style>
