<template>
  <header class="bg">
    <nav class="navbar nav two navbar-expand-lg navbar-light m-0">
      <div class="container flex-column flex-sm-row justify-content-center justify-content-sm-between">
        <router-link to="/home">
          <img class="navbar-brand m-0" src="@/assets/img/solem.svg" alt="SOLEM" />
        </router-link>
        <h3 v-if="$route.path.includes('/forms/hallazgo') || $route.path.includes('/resultado/hallazgo')"
          class="title blue">
          Reporte de hallazgo
        </h3>
        <h3 v-else-if="$route.path.includes('/forms/incidente') || $route.path.includes('/resultado/incidente')"
          class="title purple">
          Reporte de incidente interno
        </h3>
        <div v-else class="buttons">
          <div class="dropdown mt-2 mt-sm-0 mr-3">
            <button class="btn btn-secondary dropdown-toggle" :title="this.usermail" type="button" data-bs-toggle="dropdown"
              data-bs-auto-close="outside" aria-expanded="false">
              <span class="name">{{ this.viewusername }}</span>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click="logout">Cerrar Sesión</a></li>
              <li><a class="dropdown-item disabled" href="#">Versión {{ version }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { authState } from '@/authState'
import { KeycloakInstance } from '@/keycloak'

export default {
  name: 'HeaderComponent',
  data: function () {
    return {
      viewusername: '',
      usermail: '',
      version: process.env.VUE_APP_VERSION
    }
  },
  computed: {
    currentRoute () {
      return this.$route.path
    }
  },
  setup () {
    return { authState }
  },
  methods: {
    login () {
      KeycloakInstance.login({ redirectUri: window.location.origin })
    },
    logout () {
      KeycloakInstance.logout({ redirectUri: window.location.origin })
    }
  },
  created () {
    if (KeycloakInstance.authenticated && KeycloakInstance.tokenParsed && KeycloakInstance.tokenParsed.name) {
      this.viewusername = KeycloakInstance.tokenParsed.name
      this.usermail = KeycloakInstance.tokenParsed.email
    }
  }
}
</script>
